import { dev } from '$app/environment';
import * as Sentry from '@sentry/sveltekit';
import { handleErrorWithSentry } from '@sentry/sveltekit';
import { redirect, type Handle } from '@sveltejs/kit';
import { CARD_PREFIX } from './constants';

if (!dev) {
  Sentry.init({
    dsn: 'https://e5e53731041b49308219ed31915925ef@o4504949314617344.ingest.sentry.io/4504949369929728',
    // tracesSampleRate: 0,
    // replaysSessionSampleRate: 0,
    // replaysOnErrorSampleRate: 1.0,
    // integrations: [new Sentry.Replay()],

    beforeSend(event, hint) {
      if (
        event.message?.includes(
          'An internal error was encountered in the Indexed Database server'
        ) ||
        event.message?.includes('Connection to Indexed Database server lost')
      ) {
        window?.location?.reload();
      }

      return event;
    },
  });
}

// const myHandler = (async ({ event, resolve }) => {
//   const hostName = event.url.host;
//   const isCard = hostName.startsWith(CARD_PREFIX);
//   const isCardRoute = event.route.id === '/[slug]';

//   if (isCard && !isCardRoute) {
//     const replacedUrl = event.url.href.replace(CARD_PREFIX, '');
//     throw redirect(301, replacedUrl);
//   } else if (!isCard && isCardRoute) {
//     const slug = event.params.slug;
//     throw redirect(301, `/business/${slug}`);
//   }

//   const response = await resolve(event);
//   return response;
// }) satisfies Handle;

// export const handle = myHandler;

export const handleError = handleErrorWithSentry();
